import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';

import { useAttributeFiltersQuery } from '../../lib/hooks/useAttributeFiltersQuery';
import { ApolloErrorGuard } from '../../components/shared/ApolloErrorGuard/ApolloErrorGuard';
import { HandleLoadingState } from '../../components/shared/HandleLoadingState/HandleLoadingState';
import { BulkLoader } from '../../components/shared/BulkLoader/BulkLoader';
import { ProductSingleLoader } from '../ProductSingle/ProductSingleLoader';
import { OptionsForm } from './OptionsForm';
import { ReferencesForm } from './ReferencesForm';
import { ContactsForm } from './ContactsForm';
import { useFormik } from 'formik';
import {
  CUSTOMIZEDRESS,
  CustomizeDressFormInput,
  CustomizeDressFormResponse,
  CustomizeDressVariables,
} from '../../queries/mutations/dressMutation';
import { formValidation, messages } from '../../lib/translation/strings';
import { logError } from '../../lib/helpers/logError';
import { Alert } from '../../components/Notifications/Alert';
import { LoadingIcon } from '../../components/shared/LoadingIcon/LoadingIcon';
import { CUSTOM_SIZE_ATTRIBUTES } from '../../constants';

import './CustomizeDress.scss';

type CustomAttributes = {
  [key in (typeof CUSTOM_SIZE_ATTRIBUTES)[number]]: string;
};

export const CustomizeDress = () => {
  const { t } = useTranslation();

  const attributesFiltersQuery = useAttributeFiltersQuery();

  const [customizeDressFormSubmission, customizeDressForm] = useMutation<
    CustomizeDressFormResponse,
    CustomizeDressVariables
  >(CUSTOMIZEDRESS);

  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const stepRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  useEffect(() => {
    setActiveStep(1);
    setCompletedSteps([0, 0]);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []);

  const initialValues = {
    color: '',
    occasion: [],
    size: '',
    type: [],
    fabric_type: '',
    photo: '',
    description: '',
    full_name: '',
    phone_code: '',
    phone: '',
    email: '',
    category: '',
    appointment_type: 'in-boutique',
    online_appointment_type: '',
    attributes: CUSTOM_SIZE_ATTRIBUTES.reduce((accumulator, attr) => {
      accumulator[attr] = '';
      return accumulator;
    }, {} as CustomAttributes),
  };

  const validationSchema = [
    Yup.object().shape({
      color: Yup.string().required(t(formValidation.selectVariation)),
      occasion: Yup.array().min(1, formValidation.selectVariation),
      size: Yup.string().required(t(formValidation.selectVariation)),
      type: Yup.array().min(1, formValidation.selectVariation),
    }),
    Yup.object().shape({
      photo: Yup.string().required(t(formValidation.photo)),
    }),
    Yup.object().shape({
      full_name: Yup.string().required(t(formValidation.firstName)),
      phone_code: Yup.string().required(t(formValidation.phonePrefix)),
      phone: Yup.string().required(t(formValidation.phone)),
      email: Yup.string().required(t(formValidation.firstName)),
      category: Yup.string().required(t(formValidation.category)),
      appointment_type: Yup.string().required(t(formValidation.appointmentType)),
    }),
  ];

  const formik = useFormik<CustomizeDressFormInput>({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: validationSchema[activeStep - 1],
    onSubmit: async (values, formikHelpers) => {
      // Mark step as completed and move to the next step
      if (!completedSteps.includes(activeStep)) {
        setCompletedSteps([...completedSteps, activeStep]);
      }

      // Focus correct section
      focusStepHeader(activeStep + 1);

      if (activeStep < 3) {
        setActiveStep(activeStep + 1);
      } else {
        try {
          const input = {
            ...values,
            attributes: JSON.stringify(values.attributes),
          };

          await customizeDressFormSubmission({
            variables: {
              input: input,
            },
          });

          // Reset Form
          formikHelpers.resetForm();

          // Reset Active and Completed steps
          setActiveStep(1);
          setCompletedSteps([]);
        } catch (error) {
          logError(error);
        }
      }
    },
  });

  const focusStepHeader = (step: number) => {
    setTimeout(() => {
      stepRefs[step - 1]?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 300);
  };

  const handleStepClick = (step: number) => {
    // Open step only if it's already completed or the current active step
    if (step === activeStep || completedSteps.includes(step)) {
      setActiveStep(step);

      focusStepHeader(step);
    }
  };

  const isStepCompleted = (step: number) => completedSteps.includes(step);

  return (
    <div className='CustomDress'>
      <Container>
        <HandleLoadingState
          loading={attributesFiltersQuery.loading || customizeDressForm.loading}
          loadingPlaceholder={
            attributesFiltersQuery.loading ? (
              <Row>
                <Col xs='12' md='12'>
                  <BulkLoader length={1}>
                    <ProductSingleLoader />
                  </BulkLoader>
                </Col>
              </Row>
            ) : (
              <div className='CustomDress--loading'>
                <LoadingIcon />
              </div>
            )
          }
        >
          <ApolloErrorGuard error={attributesFiltersQuery.error}>
            {customizeDressForm.data && (
              <div className='mb-4'>
                <Alert message={t(messages.contactSuccess)} type='success' />
              </div>
            )}

            <div className='CustomDress--title'>
              <h1>Customize a dress</h1>
            </div>

            {/* Step 1 */}
            <section
              className={`CustomDress--options ${isStepCompleted(1) ? 'completed' : ''} ${
                activeStep === 1 ? 'active' : 'collapsed'
              }`}
            >
              <div
                className={`CustomDress--options--head ${isStepCompleted(1) ? 'completed' : ''}`}
                onClick={() => handleStepClick(1)}
                ref={stepRefs[0]}
              >
                <span className='subtitle'>
                  {isStepCompleted(1) ? <span className='icon'></span> : <span>1</span>}
                  Options
                </span>
              </div>

              <div className={`CustomDress--options--body ${activeStep === 1 ? 'open' : ''}`}>
                <OptionsForm
                  formik={formik}
                  colors={attributesFiltersQuery.data?.attributeFilters[0].terms as any}
                  occasions={attributesFiltersQuery.data?.attributeFilters[1].terms as any}
                  sizes={attributesFiltersQuery.data?.attributeFilters[2].terms as any}
                  types={attributesFiltersQuery.data?.attributeFilters[3].terms as any}
                />
              </div>
            </section>

            {/* Step 2 */}
            <section
              className={`CustomDress--references ${isStepCompleted(2) ? 'completed' : ''} ${
                activeStep === 2 ? 'active' : 'collapsed'
              } ${!isStepCompleted(1) ? 'disable' : ''}`}
            >
              <div
                className={`CustomDress--references--head ${isStepCompleted(2) ? 'completed' : ''} ${!isStepCompleted(2) ? 'disable' : ''}`}
                onClick={() => handleStepClick(2)}
                ref={stepRefs[1]}
              >
                <span className='subtitle'>
                  {isStepCompleted(2) ? <span className='icon'></span> : <span>2</span>}
                  Reference
                </span>
              </div>
              <div className={`CustomDress--references--body ${activeStep === 2 ? 'open' : ''}`}>
                <ReferencesForm formik={formik} />
              </div>
            </section>

            {/* Step 3*/}
            <section
              className={`CustomDress--contacts ${isStepCompleted(3) ? 'completed' : ''} ${
                activeStep === 3 ? 'active' : 'collapsed'
              } ${!isStepCompleted(2) ? 'disable' : ''}`}
            >
              <div
                className={`CustomDress--contacts--head ${isStepCompleted(3) ? 'completed' : ''} ${!isStepCompleted(3) ? 'disable' : ''}`}
                onClick={() => handleStepClick(3)}
                ref={stepRefs[2]}
              >
                <span className='subtitle'>
                  {isStepCompleted(3) ? <span className='icon'></span> : <span>3</span>}
                  Information
                </span>
              </div>
              <div className={`CustomDress--contacts--body ${activeStep === 3 ? 'open' : ''}`}>
                <ContactsForm formik={formik} />
              </div>
            </section>
          </ApolloErrorGuard>
        </HandleLoadingState>
      </Container>
    </div>
  );
};
