import React from 'react';
import { FormikValues } from 'formik';
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { customizeDress } from '../../lib/translation/strings';
import { CUSTOM_SIZE_ATTRIBUTES } from '../../constants';

import './CustomizeDress.scss';

interface Options {
  id: string;
  name: string;
}

interface Props {
  formik: FormikValues;
  colors: Options[];
  occasions: Options[];
  sizes: Options[];
  types: Options[];
}

export const OptionsForm = ({ formik, colors, occasions, sizes, types }: Props) => {
  const { t } = useTranslation();

  // Define custom order for sizes
  const customOrder = ['XS', 'S', 'M', 'L', 'XL', 'Custom'];

  // Create a shallow copy of the array and sort it based on the custom order
  const sortedSizes = [...sizes].sort((a, b) => {
    return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
  });

  return (
    <div className='OptionsForm'>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          {/* Color Section */}
          <Col xs='12'>
            <FormGroup className='form-group'>
              <Label for='color'>{t(customizeDress.color)}</Label>

              <div className='form-color'>
                {colors.map((color) => (
                  <Label key={color.id} className='form-color--label'>
                    <Input
                      type='radio'
                      name='color'
                      value={color.name}
                      id={color.name}
                      checked={formik.values.color === color.name}
                      onChange={formik.handleChange}
                      className='form-control'
                    />
                    <div
                      className='form-content'
                      style={{
                        borderColor: formik.values.color === color.name ? '#000' : '#ededed',
                      }}
                    >
                      <div style={{ backgroundColor: color.name }}></div>
                    </div>
                  </Label>
                ))}
              </div>

              {formik.touched.color && formik.errors.color && (
                <span className='invalid'>{formik.errors.color}</span>
              )}
            </FormGroup>
          </Col>

          {/* Occasion Section */}
          <Col xs='12'>
            <FormGroup className='form-group'>
              <Label for='occasion'>{t(customizeDress.occasion)}</Label>

              <div className='form-checkbox'>
                {occasions.map((occasion) => (
                  <Label key={occasion.id} className='form-checkbox--label'>
                    <Input
                      type='checkbox'
                      name='occasion'
                      value={occasion.name}
                      checked={formik.values.occasion.includes(occasion.name)}
                      onChange={formik.handleChange}
                      className='form-control'
                    />
                    <div
                      className='form-checkbox--label_list_item'
                      data-selected={formik.values.occasion.includes(occasion.name)}
                    >
                      <span>{occasion.name}</span>
                    </div>
                  </Label>
                ))}
              </div>

              {formik.touched.occasion && formik.errors.occasion && (
                <span className='invalid'>{formik.errors.occasion}</span>
              )}
            </FormGroup>
          </Col>

          {/* Size Section */}
          <Col xs='12'>
            <FormGroup className='form-group'>
              <Label for='size'>Size</Label>

              <div className='form-size'>
                {sortedSizes.map((size) => (
                  <Label
                    key={size.id}
                    className='form-size--label'
                    style={{
                      borderColor: formik.values.size === size.name ? '#000' : ' #ededed',
                    }}
                  >
                    <Input
                      type='radio'
                      name='size'
                      value={size.name}
                      id={size.id}
                      checked={formik.values.size === size.name}
                      onChange={formik.handleChange}
                      className='form-control'
                    />
                    {size.name}
                  </Label>
                ))}
              </div>

              {formik.touched.size && formik.errors.size && (
                <span className='invalid'>{formik.errors.size}</span>
              )}
            </FormGroup>

            {formik.values.size === 'Custom' && (
              <Row className='form-attribute'>
                {CUSTOM_SIZE_ATTRIBUTES.map((attribute, index) => (
                  <Col xs='6' md='3' key={index}>
                    <FormGroup>
                      <Label for={`attributes.${attribute}`} className='attribute-label'>
                        {attribute}
                      </Label>
                      <Input
                        id={`attributes.${attribute}`}
                        name={`attributes.${attribute}`}
                        type='text'
                        placeholder='e.g. 10cm'
                        className='attribute-control'
                        value={formik.values.attributes[attribute]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.attributes?.[attribute] &&
                        formik.errors.attributes?.[attribute] && (
                          <span className='invalid'>{formik.errors.attributes[attribute]}</span>
                        )}
                    </FormGroup>
                  </Col>
                ))}
              </Row>
            )}
          </Col>

          {/* Type Section */}
          <Col xs='12'>
            <FormGroup className='form-group'>
              <Label for='type'>{t(customizeDress.type)}</Label>

              <div className='form-checkbox'>
                {types.map((type) => (
                  <Label key={type.id} className='form-checkbox--label'>
                    <Input
                      type='checkbox'
                      name='type'
                      value={type.name}
                      checked={formik.values.type.includes(type.name)}
                      onChange={formik.handleChange}
                      className='form-control'
                    />
                    <div
                      className='form-checkbox--label_list_item'
                      data-selected={formik.values.type.includes(type.name)}
                    >
                      <span>{type.name}</span>
                    </div>
                  </Label>
                ))}
              </div>

              {formik.touched.type && formik.errors.type && (
                <span className='invalid'>{formik.errors.type}</span>
              )}
            </FormGroup>
          </Col>

          {/* Fabric Type Section */}
          <Col xs='12'>
            <FormGroup className='form-group'>
              <Label for='fabric_type'>{t(customizeDress.fabricType)}</Label>
              <Input
                id='fabric_type'
                type='textarea'
                name='fabric_type'
                value={formik.values.fabric_type}
                onChange={formik.handleChange}
                className='form-control textarea-input'
                placeholder={t(customizeDress.fabricTypePlaceholder)}
                invalid={!!formik.errors.fabric_type && formik.touched.fabric_type}
              />
              <FormFeedback>{formik.errors.fabric_type}</FormFeedback>
            </FormGroup>
          </Col>

          <Col xs='12' className='text-end'>
            <button type='submit'>Continue</button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
