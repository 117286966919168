import gql from 'graphql-tag';

export type CustomizeDressFormResponse = {
  customizeDressForm: {
    message: string;
  };
};

export interface CustomizeDressVariables {
  input: CustomizeDressFormInput;
}

export type CustomizeDressFormInput = {
  color: string;
  occasion: string[];
  size: string;
  type: string[];
  fabric_type: string;
  photo: string;
  description: string;
  full_name: string;
  phone_code: string;
  phone: string;
  email: string;
  category: string;
  appointment_type: string;
  online_appointment_type: string;
  attributes: any;
};

export const CUSTOMIZEDRESS = gql`
  mutation CustomizeDress($input: CustomizeDressFormInput!) {
    customizeDressForm(input: $input) {
      message
    }
  }
`;
