import React, { useEffect, useState } from 'react';
import { FormikValues } from 'formik';
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { customizeDress } from '../../lib/translation/strings';
import { ReactComponent as ShoppingCartIcon } from '../../assets/icons/upload.svg';

import './CustomizeDress.scss';

interface Props {
  formik: FormikValues;
}

export const ReferencesForm = ({ formik }: Props) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState<string>(formik.values.photo);

  useEffect(() => {
    setPreview(formik.values.photo);
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;

        // Update Formik's `values` for the base64 string
        formik.setFieldValue('photo', base64String);

        // Update the preview for the image
        setPreview(base64String);
      };

      // Convert file to base64
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='ReferencesForm'>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs='12' md='6'>
            <FormGroup className='form-group'>
              <Label htmlFor='photo'>{t(customizeDress.photo)}</Label>

              <div className='form-photo'>
                <Label
                  className='form-photo--label'
                  onClick={() => document.getElementById('photo')?.click()}
                >
                  {preview ? (
                    <img
                      src={preview}
                      alt='Reference Photo'
                      className='form-photo--label--image-preview'
                    />
                  ) : (
                    <div className='form-photo--label--upload-placeholder'>
                      <ShoppingCartIcon />
                      <p>{t(customizeDress.referencePhoto)}</p>
                    </div>
                  )}
                </Label>

                <Input
                  id='photo'
                  type='file'
                  name='photo'
                  accept='image/*'
                  className='form-control'
                  onChange={handleFileChange}
                />

                {formik.touched.photo && formik.errors.photo && (
                  <span className='invalid'>{formik.errors.photo}</span>
                )}
              </div>
            </FormGroup>
          </Col>

          <Col xs='12' md='6'>
            <FormGroup className='form-group'>
              <Label for='description'>{t(customizeDress.description)}</Label>
              <Input
                id='description'
                type='textarea'
                name='description'
                value={formik.values.description}
                onChange={formik.handleChange}
                className='form-control textarea-input'
                placeholder={t(customizeDress.descriptionPlaceholder)}
              />
              <FormFeedback>{formik.errors.description}</FormFeedback>
            </FormGroup>
          </Col>

          <Col xs='12' className='text-end'>
            <button type='submit'>Continue</button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
