import React, { Suspense } from 'react';
import { useScrollToTop } from './lib/hooks/useScrollToTop';
import { useAuthContext } from './lib/context/AuthContext/AuthContext';
import { Partytown } from '@builder.io/partytown/react';
import { NotificationContextProvider } from './lib/context/NotificationContext/NotificationContextProvider';
import { Routes } from './routes/Routes';
import { useMenuSidebar } from './lib/context/MenuSidebarContext/MenuSidebarContext';

const Header = React.lazy(() =>
  import('./layout/Header/Header').then((module) => ({
    default: module.Header,
  })),
);

const Footer = React.lazy(() =>
  import('./layout/Footer/Footer').then((module) => ({
    default: module.Footer,
  })),
);

const LoadingPlaceholder = React.lazy(() =>
  import('./components/shared/LoadingPlaceholder/LoadingPlaceholder').then((module) => ({
    default: module.LoadingPlaceholder,
  })),
);

const WishlistSidebar = React.lazy(() =>
  import('./layout/WishlistSidebar/WishlistSidebar').then((module) => ({
    default: module.WishlistSidebar,
  })),
);

const CartSidebar = React.lazy(() =>
  import('./layout/CartSidebar/CartSidebar').then((module) => ({
    default: module.CartSidebar,
  })),
);

const LoadingIcon = React.lazy(() =>
  import('./components/shared/LoadingIcon/LoadingIcon').then((module) => ({
    default: module.LoadingIcon,
  })),
);

import './App.scss';

export const App = () => {
  useScrollToTop();

  const authCtx = useAuthContext();
  const menuSidebar = useMenuSidebar();

  const handleOnClick = () => {
    menuSidebar.closeSidebar('wishlist');
    menuSidebar.closeSidebar('cart');
  };

  const isLoading = authCtx.isLoading || authCtx.isLoadingData;
  const isShopPage = location.pathname.startsWith('/shop');

  return (
    <Suspense>
      <div className={`App ${isLoading ? 'App--loading' : ''}`} id='App'>
        {process.env.REACT_APP_ENV === 'development' && (
          <>
            <Partytown debug={true} forward={['dataLayer.push']} />
            <script
              async
              type='text/partytown'
              src='https://www.googletagmanager.com/gtag/js?id=G-PV8CR74H2E '
            />
            <script
              type='text/partytown'
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'G-PV8CR74H2E ');
            `,
              }}
            />
          </>
        )}
        {isLoading && <LoadingPlaceholder loadingPlaceholder={<LoadingIcon />} />}
        <div className='App__backdrop' onClick={handleOnClick} />
        <Header />
        <div className={`App__main_content ${isShopPage ? 'no-padding' : ''}`}>
          <NotificationContextProvider>
            <Routes />
          </NotificationContextProvider>
        </div>
        <Footer />

        <WishlistSidebar />
        <CartSidebar />
      </div>
    </Suspense>
  );
};
