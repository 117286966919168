import React, { Suspense } from 'react';
import { Container } from 'reactstrap';

const HomeVideo = React.lazy(() =>
  import('../../components/home/HomeVideo/HomeVideo').then((module) => ({
    default: module.HomeVideo,
  })),
);

const HomePrimaryCollection = React.lazy(() =>
  import('../../components/home/HomePrimaryCollection/HomePrimaryCollection').then((module) => ({
    default: module.HomePrimaryCollection,
  })),
);

const HomeSecondaryCollection = React.lazy(() =>
  import('../../components/home/HomeSecondaryCollection/HomeSecondaryCollection').then(
    (module) => ({
      default: module.HomeSecondaryCollection,
    }),
  ),
);

const HomeOtherCollections = React.lazy(() =>
  import('../../components/home/HomeOtherCollections/HomeOtherCollections').then((module) => ({
    default: module.HomeOtherCollections,
  })),
);

const HomeProcesses = React.lazy(() =>
  import('../../components/home/HomeProcesses/HomeProcesses').then((module) => ({
    default: module.HomeProcesses,
  })),
);

import './Home.scss';

export const Home = () => {
  return (
    <div className='Home'>
      <Suspense>
        <HomeVideo />
        <Container>
          <HomePrimaryCollection />
        </Container>
        <HomeSecondaryCollection />
        <Container>
          <HomeOtherCollections />
          <HomeProcesses />
        </Container>
      </Suspense>
    </div>
  );
};
