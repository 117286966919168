import React from 'react';
import cs from 'classnames';
import { Container } from 'reactstrap';

import { HeadingTitle } from '../../components/shared/HeadingTitle/HeadingTitle';
import { ThemeContainer } from '../../components/shared/ThemeContainer/ThemeContainer';
import { Image } from '../../components/shared/Image/Image';

import './DefaultPage.scss';

interface Props {
  title?: string;
  content?: string;
  excerpt?: string;
  srcSet?: string;
  featuredImage?: string;
}

export const DefaultPage = (props: Props) => {
  const classes = cs('DefaultPage', {
    'DefaultPage--no_thumb': !props.featuredImage,
  });

  const sanitizeHtml = (html: string) => {
    const siteUrl = 'https://management.valentinecouture.com';

    // Update src to use the correct domain
    html = html.replace(/src="(https?:\/\/i0\.wp\.com\/)?([^"]*)"/g, (_, prefix, path) => {
      if (path.startsWith('wp-content')) {
        return `src="${siteUrl}/${path}"`;
      }
      return `src="${siteUrl}/${path}"`;
    });

    // Remove `srcset` attributes entirely
    html = html.replace(/srcset="[^"]*"/g, '');

    return html;
  };

  return (
    <div className={classes}>
      {props.title && (
        <ThemeContainer>
          <HeadingTitle title={props.title || ''} />

          {props.featuredImage && (
            <div className='DefaultPage__thumb'>
              <Image src={props.featuredImage} srcSet={props.srcSet} alt={props.title} />
            </div>
          )}
        </ThemeContainer>
      )}

      <div className='DefaultPage__content'>
        <Container>
          <>
            <ThemeContainer>
              {props.excerpt && (
                <div className='DefaultPage__heading'>
                  <div className='DefaultPage__heading__excerpt'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(props.excerpt),
                      }}
                    />
                  </div>
                </div>
              )}

              {props.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(props.content) || '',
                  }}
                />
              )}
            </ThemeContainer>
          </>
        </Container>
      </div>
    </div>
  );
};
